import {Text} from "@chakra-ui/react";
import React from "react";

type Props = { children: React.ReactNode };

export default function Title({children}: Props) {
  return (
    <Text
      margin="0 0 30px"
      lineHeight="1.15"
      fontSize={'25px'}
      fontWeight="600"
    >
      {children}
    </Text>
  );
}
