import React, { FC, useState } from "react";
import useBlackList from "hooks/useBlackList";
import DeleteIcon from "../../Icons/DeleteIcon";
import classNames from "classnames";
import { isAddress } from "utils/contractsHelper";
import EmptyRow from "../../Form/EmptyRow";
import { usePagination } from "../../../hooks/usePagination";
import styled from "styled-components";

interface BlacklistProps {
  profileId: number;
}

const StyledTd = styled.td`
  display: flex;
  align-items: end;
  gap: 8px;
`;
const StyledTable = styled.table`
  margin-top: 15px;
`;

const Blacklist: FC<BlacklistProps> = ({ profileId }) => {
  const [modal, setModal] = React.useState(false);
  const { list, create, remove } = useBlackList();
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>("");

  const changeAddress = (value: string) => {
    setAddress(value);
    setError("");
  };

  function showModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  const handleSubmit = async () => {
    if (!isAddress(address)) {
      setError("Please type valid address");
      return;
    }
    await create({
      address,
      profileId,
      network: "bsc",
    });
    setAddress("");
    closeModal();
  };

  const handleRemove = (id) => async () => {
    await remove(id);
  };

  const blackListList = usePagination(list);

  return (
    <>
      <div className="row">
        <div className="col-md-12 text-end">
          <button className="add-btn" onClick={showModal}>
            <i className="icon-plus"></i>
            <span>Add Address</span>
          </button>
        </div>
      </div>

      <div className="t-data">
        <div className="p-table">
          <div className="container">
            <div className="p-table__wrap">
              <StyledTable>
                <thead>
                  <tr>
                    <th scope="col">Address</th>
                  </tr>
                </thead>
                <tbody>
                  {!blackListList.list.length && <EmptyRow rows={2} />}
                  {blackListList.list.map((element) => (
                    <tr key={element.id}>
                      <StyledTd>
                        <a
                          href={`https://bscscan.com/address/${element.address}`}
                          target="_blank"
                          className=""
                        >
                          {element.address}
                        </a>
                        <span onClick={handleRemove(element.id)}>
                          <DeleteIcon />
                        </span>
                      </StyledTd>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </div>
      </div>

      {blackListList.pagination}

      <div className={modal ? "modal fade show d-block" : "modal fade"}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Blacklist Address</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Address</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className={classNames(
                      "form-control",
                      error !== "" && "is-invalid"
                    )}
                    value={address}
                    onChange={(e) => changeAddress(e.target.value)}
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={handleSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={modal ? "modal-backdrop fade show" : "d-none"}></div>
    </>
  );
};

export default Blacklist;
