import React from "react";
import "./MessageInfo.css";

interface MessageInfoProps {
  content: string;
  abbreviateAddress?: (address: string, length: number) => string;
  currentProfile?: {
    withdrawerAddr: string;
  };
}

const MessageInfo: React.FC<MessageInfoProps> = ({
  content,
  abbreviateAddress,
  currentProfile,
}) => {
  const abbreviatedAddress = currentProfile
    ? abbreviateAddress?.(currentProfile.withdrawerAddr, 6)
    : "";

  return (
    <div className="information-message">
      <img src={"/icons/information-line.svg"} alt="img" />
      {content}
      {abbreviatedAddress}
    </div>
  );
};

export default MessageInfo;
