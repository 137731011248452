import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { getBnbBalances } from "utils/contractsHelper";
import { useVaultsFactory } from "hooks/useContract";
import { IBalances } from "dtos/IBalances";
import CopyIcon from "../../Icons/CopyIcon";
import { abbreviateAddress } from "utils/abbreveareAddress";
import { apiUrl, post } from "../../../utils/fetcher";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { default as ToastDescriptionWithTx } from "components/Toast/DescriptionWithTx";
import { usePagination } from "../../../hooks/usePagination";
import useCurrentProfile from "../../../hooks/useCurrentProfile";
import refresh from "../../../assets/icons/refresh.svg";
import { truncateAddress } from "../../../utils/wallet";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import ConnectModal from "../../ConnectModal";
import styled from "styled-components";
import MessageInfo from "components/MessageInfo/MessageInfo";

interface AccountsProps {
  accounts: {
    buyer: string[];
    seller: string[];
  };
  hash: string;
}

type AccountType = "buyer" | "seller";

interface IAccount {
  address: string;
  type: AccountType;
}

enum AccountTypeName {
  buyer = "Buyer",
  seller = "Seller",
}

const StyledTable = styled.table`
  margin-top: 15px;
`;

const StyledTd = styled.td`
  display: flex;
  align-items: end;
  gap: 8px;
`;

const StyledBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 30px;

  border-radius: 4px;
  opacity: 0.9;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`;

const StyledButtonWithdrawal = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #5790ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  text-decoration-line: underline;
`;

const Accounts: FC<AccountsProps> = ({ accounts, hash }) => {
  const [loading, setLoading] = useState<{
    [address: string]: boolean;
  }>({});
  const vaultsFactoryContract = useVaultsFactory(true);
  const [balances, setBalances] = useState<IBalances>({});
  const toastSuccess = useToast({ status: "success", position: "top" });
  const toastError = useToast({ status: "error", position: "top" });
  const { hasRights } = useCurrentProfile();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { account, active } = useWeb3React();
  const { logout } = useAuth();

  const _accounts: IAccount[] = [
    ...accounts.buyer.map(
      (address): IAccount => ({
        address,
        type: "buyer",
      })
    ),
    ...accounts.seller.map(
      (address): IAccount => ({
        address,
        type: "seller",
      })
    ),
  ];

  const accountsList = usePagination<IAccount>(_accounts);

  const loadBalances = async () => {
    const balances = await getBnbBalances(vaultsFactoryContract, [
      ..._accounts.map(({ address }) => address),
    ]);
    setBalances(balances);
  };

  useEffect(() => {
    let interval;
    if (_accounts.length) {
      interval = setInterval(() => {
        loadBalances();
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [_accounts.join(",")]);

  const withdraw = (address: string) => async () => {
    setLoading((values) => ({
      ...values,
      [address]: true,
    }));
    const data = await post(apiUrl("/profile/withdraw"), { address, hash });
    if (!data.hash) {
      toastError({
        description: data.message || "Error",
      });
    } else {
      setBalances((values) => ({
        ...values,
        [address]: "0",
      }));
      toastSuccess({
        description: (
          <ToastDescriptionWithTx txHash={data.hash}>
            'Withdrawal successful'
          </ToastDescriptionWithTx>
        ),
      });
    }
    setLoading((values) => ({
      ...values,
      [address]: false,
    }));
  };

  return (
    <>
      <MessageInfo
        content={` These Addresses will trigger operations on Main Vault. Addresses will
        become active and start operations as soon as they will have Native
        currency for gas.`}
      />

      <div className="row mt-3">
        <div className="col-md-12 text-end">
          {!active ? (
            // <button
            //   type="button"
            //   className="btn btn-outline-secondary btn-sm"
            //   style={{ marginRight: 10 }}
            //   onClick={onOpen}
            // >
            //   Connect Wallet
            // </button>

            <button
              type="button"
              className="add-btn"
              style={{ marginRight: 10 }}
              onClick={onOpen}
            >
              Connect Wallet
            </button>
          ) : (
            // <button
            //   type="button"
            //   className="btn btn-success btn-sm"
            //   style={{ marginRight: 10 }}
            //   onClick={logout}
            // >
            //   {truncateAddress(account)}
            // </button>
            <button
              type="button"
              className="add-btn"
              style={{ marginRight: 10 }}
              onClick={logout}
            >
              {truncateAddress(account)}
            </button>
          )}
        </div>
      </div>

      <div className="t-data">
        <div className="p-table">
          <div className="container">
            <div className="p-table__wrap">
              <StyledTable>
                <thead>
                  <tr>
                    <th scope="col">Address</th>
                    <th scope="col">Type</th>
                    <th scope="col">Native Balance (BNB)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {accountsList.list.map((account) => (
                    <tr key={account.address}>
                      <StyledTd>
                        {abbreviateAddress(account.address, 6)}
                        <CopyIcon text={account.address} />
                      </StyledTd>
                      <td>
                        <StyledBadge
                          className={classNames(
                            "badge",
                            account.type === "seller"
                              ? "text-bg-danger"
                              : "text-bg-success"
                          )}
                        >
                          {AccountTypeName[account.type]}
                        </StyledBadge>
                      </td>
                      <td>{balances[account.address] || "..."}</td>
                      <td className="text-center">
                        <StyledButtonWithdrawal
                          onClick={withdraw(account.address)}
                          className=""
                          disabled={
                            !hasRights ||
                            loading[account.address] ||
                            !balances[account.address] ||
                            +balances[account.address] === 0
                          }
                        >
                          Withdrawal
                        </StyledButtonWithdrawal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </div>
      </div>
      {accountsList.pagination}
      <ConnectModal isOpen={isOpen} closeModal={onClose} />
    </>
  );
};

export default Accounts;
