import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import PageLayout from "../../layout/Layout";
import Title from "../Title";
import { IStrategy } from "dtos/IStrategy";
import { useToast } from "@chakra-ui/react";
import styled from "styled-components";
import "../../assets/styles/pages/_profile-actions.scss";
import "../../assets/styles/components/_profile-form.scss";

const FormWrapper = styled.div``;

const MessageInfo = styled.div`
  max-width: 628px;
  height: 100%;

  padding: 22px 24px 22px 16px;

  display: flex;
  align-items: start;
  gap: 12px;

  border-radius: 4px;
  background: #578fff1a;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 21px;
`;

export type StrategiesProps = Omit<IStrategy, "id">;


interface StrategyFormProps {
  title: string;
  onSubmit: (data: StrategiesProps) => any;
  defaultValues: {
    type?: string;
    minBaseAssetPrice?: number;
    maxBaseAssetPrice?: number;
    fill?: number;
    minBalance?: number;
  };
}

const StrategyForm: React.FC<StrategyFormProps> = ({
  title,
  onSubmit,
  defaultValues,
}) => {
  const { register, watch, handleSubmit } = useForm<StrategiesProps>();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const toastSuccess = useToast({ status: "success", position: "top" });
  const toastError = useToast({ status: "error", position: "top" });

  const _onSubmit: SubmitHandler<StrategiesProps> = async (data) => {
    const result = onSubmit(data);

    if (!result || result?.error) {
      toastError({
        description: Array.isArray(result?.message)
          ? result?.message.join(", ")
          : result?.message || "Please ty again latter",
      });
    } else {
      toastSuccess({ description: "Strategy created" });
      history.push(`/profile/${params.id}`);
    }
  };

  return (
    <PageLayout>
      <div className="container">
        <div className="profile-section">
          <div className="">
            <Title>{title}</Title>
          </div>
          <form className="profile-form strategy"
            onSubmit={handleSubmit(_onSubmit)}
          >
            <div className="profile-form__wrap">
              <div className="profile-form__left">
                <div className="profile-form__it">
                  <label className="">
                    Mempool Trade Trigger Type:
                    <div className="d-inline-block tooltipItem">
                      <img
                        src={"/icons/info.svg"}
                        style={{
                          width: "24px",
                          cursor: "pointer",
                          marginLeft: 10,
                        }}
                      />
                      <div className="tooltipArea" style={{ marginTop: -110 }}>
                        Info: This mempol trade operation will trigger bot to
                        execute the strategy. Example: Bot will start Sell
                        counter strategy after discover Buy trade.
                      </div>
                    </div>
                  </label>
                  <div className="">
                    <select
                      className="form-select"
                      defaultValue={defaultValues.type}
                      {...register("type", { required: true })}
                      style={{ display: "inline-block" }}
                    >
                      <option value="buy">buy</option>
                      <option value="sell">sell</option>
                    </select>
                  </div>
                </div>
                <div className="profile-form__it">
                  <label className="">Min Base Asset Price $:</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={defaultValues.minBaseAssetPrice}
                      {...register("minBaseAssetPrice", { required: true })}
                    />
                  </div>
                </div>
                <div className="profile-form__it">
                  <label className="">Max Base Asset Price $:</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={defaultValues.maxBaseAssetPrice}
                      {...register("maxBaseAssetPrice", { required: true })}
                    />
                  </div>
                </div>
                <div className="profile-form__it">
                  <label className="">
                    Counter Trade Fill %:
                    <div className="d-inline-block tooltipItem">
                      <img
                        src={"/icons/info.svg"}
                        style={{
                          width: "24px",
                          cursor: "pointer",
                          marginLeft: 10,
                        }}
                      />
                      <div className="tooltipArea" style={{ marginTop: -130 }}>
                        Info: After bot discovers Mempool Trade Trigger Type in
                        mempool and the price is in specific range - it will
                        create counter trade with amount equal to % of
                        discovered trade.
                      </div>
                    </div>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={defaultValues.fill}
                      {...register("fill", { required: true })}
                    />
                  </div>
                </div>
                <div className="profile-form__it">
                  <label className="">Min Remaning Quote:</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={defaultValues.minBalance}
                      {...register("minBalance", { required: true })}
                    />
                  </div>
                </div>
                <div className="profile-form__it">
                  <div className=" ">
                    {/* <div className="alert alert-secondary" role="alert">
                      When a user performs a{" "}
                      <b>{watch("type") ? watch("type") : defaultValues.type}</b>{" "}
                      operation, and the trade price is between{" "}
                      <b>
                        {watch("minBaseAssetPrice")
                          ? watch("minBaseAssetPrice")
                          : defaultValues.minBaseAssetPrice}
                      </b>{" "}
                      and{" "}
                      <b>
                        {watch("maxBaseAssetPrice")
                          ? watch("maxBaseAssetPrice")
                          : defaultValues.maxBaseAssetPrice}
                      </b>{" "}
                      we create opposite trade and fill{" "}
                      <b>{watch("fill") ? watch("fill") : defaultValues.fill}</b>{" "}
                      percent from the original amount.
                    </div> */}
                    <MessageInfo>
                      <img src={"/icons/information-line.svg"} alt="img" />
                      <p>
                        When a user performs a{" "}
                        <b>
                          {watch("type") ? watch("type") : defaultValues.type}
                        </b>{" "}
                        operation, and the trade price is between{" "}
                        <b>
                          {watch("minBaseAssetPrice")
                            ? watch("minBaseAssetPrice")
                            : defaultValues.minBaseAssetPrice}
                        </b>{" "}
                        and{" "}
                        <b>
                          {watch("maxBaseAssetPrice")
                            ? watch("maxBaseAssetPrice")
                            : defaultValues.maxBaseAssetPrice}
                        </b>{" "}
                        we create opposite trade and fill{" "}
                        <b>
                          {watch("fill") ? watch("fill") : defaultValues.fill}
                        </b>{" "}
                        percent from the original amount.
                      </p>
                    </MessageInfo>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="green-btn"
                  style={{ marginRight: 10 }}
                >
                  Save
                </button>
                <Link to={`/profile/${params.id}`} className="cancel-btn">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default StrategyForm;
