import classNames from "classnames";
import IProfile from "dtos/IProfile";
import React, {FC, useEffect, useState} from "react";
import getTokenInfo from "utils/getTokenInfo";
import CopyIcon from "../Icons/CopyIcon";
import fromat from "date-fns/format";
import styled from 'styled-components'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  padding: 30px;
  border: 1px solid rgba(24, 24, 51, 0.1);
  border-radius: 16px;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding: 15px;
    gap: 10px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    display: block;

    span {
      font-weight: 400;

      &:first-child {
        font-weight: 500;
      }
    }
  }
`

const RowText = styled.span`
  color: #181833;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
    margin-right: 10px;
  }
`

const ProfileDetails: FC<{ profile: IProfile }> = ({profile}) => {
  const [tokenNames, setTokenNames] = useState<{ base: string; quote: string }>(
    {base: "-", quote: ""}
  );

  const loadTokenNames = async () => {
    const base = await getTokenInfo(profile.baseTokenAddr);
    const quote = await getTokenInfo(profile.quoteTokenAddr);

    setTokenNames({
      base: base.symbol,
      quote: quote.symbol,
    });
  };

  useEffect(() => {
    if (profile) {
      loadTokenNames();
    }
  }, [profile]);

  return (
    <TableWrapper>
      <RowWrapper>
        <RowText>Profile Id</RowText>
        <RowText>{profile.id}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Profile Name</RowText>
        <RowText>{profile.name}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Dex</RowText>
        <RowText>{profile.dex}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Status</RowText>
        <RowText
          className={classNames(
            "badge",
            profile.status === "waiting"
              ? "text-bg-warning"
              : profile.status === "active"
                ? "text-bg-success"
                : "text-bg-danger"
          )}
        >
          {profile.status}
        </RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Main Receiver Address</RowText>
        <RowWrapper>
          <span className={'me-1'}>{profile.mainReceiverAddr}</span>
          <CopyIcon text={profile.mainReceiverAddr}/>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper>
        <RowText>Vault Address</RowText>
        <RowWrapper>
          <span className={'me-1'}>{profile.vaultAddr}</span>
          <CopyIcon text={profile.vaultAddr}/>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper>
        <RowText>Withdrawer</RowText>
        <RowWrapper>
          <span className={'me-1'}>{profile.withdrawerAddr}</span>
          <CopyIcon text={profile.withdrawerAddr}/>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper>
        <RowText>Base Token</RowText>
        <RowWrapper>
          <span className={'me-1'}>{profile.baseTokenAddr}</span>
          <CopyIcon text={profile.baseTokenAddr}/>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper>
        <RowText>Quote Token</RowText>
        <RowWrapper>
          <span className={'me-1'}>{profile.quoteTokenAddr}</span>
          <CopyIcon text={profile.quoteTokenAddr}/>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper>
        <RowText>Sellers Count</RowText>
        <RowText>{profile.sellersCount}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Buyers Count</RowText>
        <RowText>{profile.buyersCount}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Gas Limit</RowText>
        <RowText>{profile.gasLimit}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Max Price Impact Per Transaction</RowText>
        <RowText>{profile.maxPriceImpact / 10}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Proxy-Receiver Max BaseToken Balance</RowText>
        <RowText>{profile.proxyMaxBaseBalance}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Proxy-Receiver Max BaseToken Balance</RowText>
        <RowText>{profile.proxyMaxBaseBalance}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Proxy-Receiver Max QuoteToken Balance</RowText>
        <RowText>{profile.proxyMaxQuoteBalance}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Min Amount For Triggering</RowText>
        <RowText>{profile.minTriggeringAmount}</RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Created At</RowText>
        <RowText>
          {profile.createdAt
            ? fromat(new Date(profile.createdAt), "MMM dd, yyyy HH:mm:ss")
            : "-"}
        </RowText>
      </RowWrapper>
      <RowWrapper>
        <RowText>Updated At</RowText>
        <RowText>{profile.updatedAt
          ? fromat(new Date(profile.updatedAt), "MMM dd, yyyy HH:mm:ss")
          : "-"}</RowText>
      </RowWrapper>
    </TableWrapper>
  );
};

export default ProfileDetails;
