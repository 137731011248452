import React from "react";

export default function InfoIcon() {
  return (
    <img
      src={'/icons/info.svg'}
      style={{width: "15px", cursor: "pointer", marginLeft: 10}}
    />
  );
}
