import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import strategiesCfg from "../../config/constants/strategies";
import contractsCfg from "../../config/constants/contracts";
import PageLayout from "../../layout/Layout";
import ProfileDetails from "./ProfileDetails";
import Strategies from "../Strategies/Strategies";
import Accounts from "./Tabs/Accounts";
import Blacklist from "./Tabs/Blacklist";
import Contracts from "./Tabs/Contracts";
import useSWR, { SWRResponse } from "swr";
import { apiUrl, get } from "utils/fetcher";
import IProfile from "dtos/IProfile";
import useProfiles from "hooks/useProfiles";
import BlackListProvider from "providers/BlackListProvider";
import { abbreviateAddress } from "utils/abbreveareAddress";
import StrategiesProvider from "../../providers/StrategiesProvider";
import { useToast } from "@chakra-ui/react";
import TokenName from "../TokenName";
import useCurrentProfile from "../../hooks/useCurrentProfile";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import MessageInfo from "components/MessageInfo/MessageInfo";

const tabs: { name: string; value: string }[] = [
  { value: "strategies", name: "Strategies" },
  { value: "trade_accounts", name: "Trade Accounts" },
  { value: "vault_contracts", name: "Vault Contracts" },
  { value: "blacklist", name: "Blacklist" },
  { value: "profile_info", name: "Profile info" },
];

const RowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 887px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;
const RowButtonsStyle = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 7px;
`;
const NameStyle = styled.h3`
  color: #181833;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  width: 100%;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;
const NetworkBlock = styled.div`
  color: #181833;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
`;
const WithdrawerBlock = styled.div`
  display: flex;
  align-items: baseline;

  color: #181833;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
`;

const ButtonTabsStyle = styled.button`
  position: relative;
  margin-right: 37px;
  margin-bottom: 7px;

  color: #18183366;
  font-size: 16px;
  font-weight: 500;

  &.active {
    color: #5790ff;

    &::after {
      content: "";
      position: absolute;
      bottom: -7px;
      left: 0;

      width: 100%;
      height: 4px;
      background: #5790ff;
      opacity: 0.8;
    }
  }
`;
const ButtonDeleteStyle = styled.button`
  width: 97px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #c32604;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 20px;
  border: 1px solid #c32604;
`;

const ButtonStatusStyle = styled.button`
  width: 97px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 20px;
  border: 1px solid;
`;

const ProfileInfo = () => {
  const [loading, setloading] = useState<boolean>(false);
  const pathParams = useParams<{ id?: string; tab?: string }>();
  const { deleteProfile } = useProfiles();
  const history = useHistory();
  const toastError = useToast({ status: "error", position: "top" });
  const { account, active } = useWeb3React();
  const { controlProfile } = useProfiles();

  const profileId = pathParams.id ? +pathParams.id : undefined;

  const currentTab = pathParams.tab || "strategies";
  const setTab = (tab) => {
    history.push(`/profile/${profileId}/${tab}`);
  };

  const profileData: SWRResponse<IProfile> = useSWR(
    profileId ? apiUrl(`/profile/${profileId}`) : null,
    get
  );

  const { currentProfile } = useCurrentProfile();

  const handleDelete = async (e) => {
    e.preventDefault();
    if (confirm("Are you sure?")) {
      if (currentProfile?.id) {
        await deleteProfile(currentProfile.id);
        history.push("/profiles");
      }
    }
  };

  useEffect(() => {
    if (profileData?.error) {
      toastError({
        description: profileData.error.message || "Can not load profile data",
      });
      history.push("/profiles");
    }
  }, [profileData?.error]);

  useEffect(() => {
    setloading(false);
  }, [profileData.data]);

  const changeProfileState = (value: "start" | "stop") => async () => {
    setloading(true);
    await controlProfile(currentProfile.id, value);
    profileData.mutate();
  };

  return (
    <PageLayout>
      {currentProfile ? (
        <div className={"container"}>
          <BlackListProvider profileId={currentProfile.id}>
            <RowStyle>
              <TitleContainer>
                <div className="">
                  <Link
                    to="/profiles"
                    style={{ top: "3px", position: "relative" }}
                  >
                    <img
                      style={{ width: "24px", cursor: "pointer" }}
                      src={"/icons/arrow_back.svg"}
                    />
                  </Link>
                </div>
                <NameStyle>{currentProfile.name}</NameStyle>
                <NetworkBlock>Network: {currentProfile.dex}</NetworkBlock>
                <WithdrawerBlock>
                  Withdrawer:{" "}
                  <span>
                    {abbreviateAddress(currentProfile.withdrawerAddr)}
                  </span>
                  {/* <div className="d-inline-block tooltipItem">
                    <img
                      src={"/icons/info.svg"}
                      style={{
                        display: "unset",
                        width: "20px",
                        cursor: "pointer",
                        marginLeft: 10,
                        marginBottom: -4,
                      }}
                    />
                    <div className="tooltipArea">
                      It should be connected before tokens withdraw action.
                    </div>
                  </div> */}
                </WithdrawerBlock>
              </TitleContainer>

              <RowButtonsStyle>
                <ButtonDeleteStyle
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={handleDelete}
                >
                  Delete
                </ButtonDeleteStyle>
                <Link
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  to={`/edit-profile/${currentProfile.id}`}
                  style={{
                    width: "97px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    fontSize: "16px",
                    fontWeight: 500,

                    borderRadius: "20px",
                    border: " 1px solid #6C757D",
                  }}
                >
                  Edit
                </Link>
                {currentProfile.status === "active" && (
                  <ButtonStatusStyle
                    type="button"
                    className="btn btn-outline-warning btn-sm"
                    style={{ marginRight: 10 }}
                    disabled={loading}
                    onClick={changeProfileState("stop")}
                  >
                    Stop
                  </ButtonStatusStyle>
                )}
                {currentProfile.status === "stopped" && (
                  <ButtonStatusStyle
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    style={{ marginRight: 10 }}
                    disabled={loading}
                    onClick={changeProfileState("start")}
                  >
                    Start
                  </ButtonStatusStyle>
                )}
              </RowButtonsStyle>
            </RowStyle>

            <nav className="d-sm-block d-xs-block d-md-none mt-5">
              <div
                className="nav nav-pills nav-fill"
                id="nav-tab"
                role="tablist"
              >
                {tabs.map((tab) => (
                  <button
                    onClick={() => setTab(tab.value)}
                    className={
                      currentTab === tab.value ? "nav-link active" : "nav-link"
                    }
                    type="button"
                    key={tab.value}
                  >
                    {tab.name}
                  </button>
                ))}
              </div>
            </nav>

            <nav className="d-none d-sm-none d-xs-none d-md-block mt-5">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {tabs.map((tab) => (
                  <ButtonTabsStyle
                    onClick={() => setTab(tab.value)}
                    className={currentTab === tab.value ? "active" : ""}
                    type="button"
                    key={tab.value}
                  >
                    {tab.name}
                  </ButtonTabsStyle>
                ))}
              </div>
            </nav>

            {active &&
              account?.toLowerCase() !==
                currentProfile.withdrawerAddr?.toLowerCase() && (
                <div className="pt-3">
                  <MessageInfo
                    content={
                      "To get full access to this profile, please connect account"
                    }
                    abbreviateAddress={abbreviateAddress}
                    currentProfile={currentProfile}
                  />
                </div>
              )}

            <div className="tab-content pt-3">
              <div
                className={
                  currentTab === "strategies"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
              >
                <StrategiesProvider profileId={currentProfile.id}>
                  <Strategies
                    strategies={strategiesCfg}
                    profileId={currentProfile.id}
                  />
                </StrategiesProvider>
              </div>
              <div
                className={
                  currentTab === "trade_accounts"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
              >
                <Accounts
                  accounts={currentProfile.addresses}
                  hash={currentProfile.hash}
                />
              </div>
              <div
                className={
                  currentTab === "vault_contracts"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
              >
                <Contracts
                  contracts={contractsCfg}
                  currentProfile={currentProfile}
                />
              </div>
              <div
                className={
                  currentTab === "blacklist"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
              >
                <Blacklist profileId={currentProfile.id} />
              </div>
              <div
                className={
                  currentTab === "profile_info"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
              >
                <ProfileDetails profile={currentProfile} />
              </div>
            </div>
          </BlackListProvider>
        </div>
      ) : (
        <div className={"container"}>
          <h2>Profile not found!</h2>
        </div>
      )}
    </PageLayout>
  );
};

export default ProfileInfo;
