import React, {FC} from "react";
import {Link} from "react-router-dom";
import useStrategies from "../../hooks/useStrategies";
import classNames from "classnames";
import EmptyRow from "../Form/EmptyRow";
import FlexCell from "../Form/FlexCell";
import Tooltip from "../Tooltip";
import {usePagination} from "../../hooks/usePagination";
import styled from "styled-components";

interface StrategiesProps {
  strategies: {
    id: number;
    type_operations: string;
    user_operation: string;
    bot_operation: string;
    min_base_price: number;
    max_base_price: number;
    fill_percent: number;
    min_remaining: number;
  }[];
  profileId: number;
}

const StyledTable = styled.table`
  margin-top: 15px;
`;

const StyledBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 30px;

  border-radius: 4px;
  opacity: 0.9;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`;

const Strategies: FC<StrategiesProps> = ({profileId}) => {
  const {list} = useStrategies();

  const strategiesList = usePagination(list);

  return (
    <>
      <div className="row">
        <div className="" style={{display: "flex", justifyContent: "start"}}>
          <Link className="add-btn" to={`/profile/${profileId}/add-strategy`}>
            <i className="icon-plus"></i>
            <span>Add strategy</span>
          </Link>
        </div>
      </div>

      <div className="t-data">
        <div className="p-table">
          <div className="container">
            <div className="p-table__wrap">
              <StyledTable>
                <thead>
                <tr>
                  <th scope="col">
                    Trigger Type
                    {/* <FlexCell>
                        Trigger Type
                        <Tooltip>
                          Info: After bot discovers Mempool Trade Trigger Type
                          in mempool and the price is in specific range - it
                          will create counter trade with amount equal to % of
                          discovered trade.
                        </Tooltip>
                      </FlexCell> */}
                  </th>
                  <th scope="col">Min Base Asset Price</th>
                  <th scope="col">Max Base Asset Price</th>
                  <th scope="col">Fill Percent</th>
                  <th scope="col">Min Remaining Base / Quote</th>
                  {strategiesList.list.length > 0 && (
                    <th scope="col">Edit</th>
                  )}
                </tr>
                </thead>
                <tbody>
                {strategiesList.list.length === 0 && <EmptyRow rows={5}/>}
                {strategiesList.list.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <StyledBadge
                        className={classNames(
                          "badge",
                          item.type === "sell"
                            ? "text-bg-danger"
                            : "text-bg-success"
                        )}
                      >
                        {item.type}
                      </StyledBadge>
                    </td>
                    <td>{item.minBaseAssetPrice}</td>
                    <td>{item.maxBaseAssetPrice}</td>
                    <td>{item.fill}%</td>
                    <td>{item.minBalance}</td>
                    <td>
                      <Link
                        to={`/profile/${profileId}/edit-strategy/${item.id}`}
                      >
                        <i className="icon-edit"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </div>
      </div>
      {strategiesList.pagination}
    </>
  );
};

export default Strategies;
