import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {showCopyToast} from "../CopyToast";

interface CopyIconProps {
  text: string;
}

const CopyIcon: React.FC<CopyIconProps> = ({text}) => {
  return (
    <div style={{cursor: "pointer"}}>
      <CopyToClipboard text={text} onCopy={() => showCopyToast(text)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path
            d="M3.66683 3.66668V1.00001C3.66683 0.823199 3.73707 0.65363 3.86209 0.528606C3.98712 0.403581 4.15669 0.333344 4.3335 0.333344H13.0002C13.177 0.333344 13.3465 0.403581 13.4716 0.528606C13.5966 0.65363 13.6668 0.823199 13.6668 1.00001V9.66668C13.6668 9.84349 13.5966 10.0131 13.4716 10.1381C13.3465 10.2631 13.177 10.3333 13.0002 10.3333H10.3335V12.9953C10.3335 13.366 10.0342 13.6667 9.66216 13.6667H1.00483C0.916644 13.6668 0.829307 13.6495 0.747818 13.6158C0.666328 13.582 0.592286 13.5326 0.52993 13.4702C0.467573 13.4079 0.418127 13.3338 0.38442 13.2524C0.350713 13.1709 0.333409 13.0835 0.333496 12.9953L0.335496 4.33801C0.335496 3.96734 0.63483 3.66668 1.00683 3.66668H3.66683ZM5.00016 3.66668H9.66216C10.0328 3.66668 10.3335 3.96601 10.3335 4.33801V9.00001H12.3335V1.66668H5.00016V3.66668ZM1.66883 5.00001L1.66683 12.3333H9.00016V5.00001H1.66883Z"
            fill="#181833"/>
        </svg>
      </CopyToClipboard>
    </div>
  );
};

export default CopyIcon;
